h1 {
  font-size: 7rem;
  text-transform: uppercase;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.char {
  transform: translateY(115px);
  transition: transform 0.65s;
}
